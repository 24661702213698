import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  InputAdornment, IconButton, MenuItem 
} from '@mui/material';
import { useInitialState, getColumns } from './dashboard/constants';
import { fetchNews, fetchData, fetchStatisticsData, fetchUnits } from './dashboard/fetchFunctions';
import { FiltersButton, BaseButton, StatisticsButton, AddPersonalButton, ExportButton } from './dashboard/Buttons';
import { useAuth } from '../context/AuthContext';
import { handleInputChange, handleClearSelection, handleSortModelChange as handleSortModelChangeHandler } from './dashboard/eventHandlers';
import Filters from './dashboard/Filters';
import Base from './dashboard/Base';
import axios from 'axios';
import DashboardTable from './dashboard/DashboardTable';
import Statistics from './dashboard/Statistics';
import EditPersonalDialog from './dashboard/EditPersonalDialog';
import DeletePersonalDialog from './dashboard/DeletePersonalDialog';
import AddPersonalDialog from './dashboard/AddPersonalDialog';
import ConfirmationDialog from './dashboard/ConfirmationDialog';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const Dashboard = () => {
  const {
    filtersOpen, setFiltersOpen,
    showBase, setShowBase,
    showStatistics, setShowStatistics,
    news, setNews,
    units, setUnits,
    rows, setRows,
    totalRecords, setTotalRecords,
    paginationModel, setPaginationModel,
    sortModel, setSortModel,
    filters, setFilters,
    locale
  } = useInitialState();

  const [unitDisplay, setUnitDisplay] = useState('');
  const [buttonDisplay, setButtonDisplay] = useState(true);

  const { user } = useAuth();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [statisticsData, setStatisticsData] = useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [exportData, setExportData] = useState({});

  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1976d2', // Primary color
      },
      '&:hover fieldset': {
        borderColor: '#1976d2', // Primary color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1976d2', // Primary color when focused
      },
    },
  });

  useEffect(() => {
    if (user.role === 'usuario') {
      setUnitDisplay(user.unit); // Establecer valor para el campo unit
      setFilters(prevFilters => ({
        ...prevFilters,
        unit: user.unit, // Asignar unit en filtros
      }));
    }
  }, [user, setFilters]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));

    if (name === 'unit') 
    {
      setUnitDisplay(value); // Actualizar visualización

      setFilters(prevFilters => ({
        ...prevFilters,
        [name]: value === 1 ? '' : value, // Asignar valor vacío para "DMQ"
      }));

      setShowStatistics(true);
    }
  };

  const handleClearSelection = (field) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: '',
    }));
    if (field === 'unit') {
      setUnitDisplay(''); // Limpiar visualización
    }
  };

  useEffect(() => {
    fetchData(filters, sortModel, paginationModel, setRows, setTotalRecords, user.token);
    fetchNews(setNews, user.token);
    fetchUnits(setUnits, user.token);
    fetchStatisticsData(filters, user.token, setStatisticsData);
  }, [filters, sortModel, paginationModel, user.token]);

  useEffect(() => {
    if (statisticsData && statisticsData.observations) {
      const { observations, totalConsiderar } = statisticsData;
      const availableCount = observations['DISPONIBLE'] || 0;
      const alertAndOriginCount = (observations['ALERTA SIIPNE 3W'] || 0) + (observations['UNIDAD DE ORIGEN'] || 0);

      const availablePercentage = calculatePercentage(availableCount, totalConsiderar);
      const alertAndOriginPercentage = calculatePercentage(alertAndOriginCount, totalConsiderar);

      user.role === 'usuario' && availablePercentage < 80 ?
      setButtonDisplay(false) :
      setButtonDisplay(true)

      setExportData({
        availablePercentage,
        availableCount,
        alertAndOriginPercentage,
        alertAndOriginCount
      });
    }
  }, [statisticsData]);

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const toggleBase = () => {
    setShowBase(!showBase);
  };

  const toggleStatistics = () => {
    setShowStatistics(!showStatistics);
  };

  const handleSortModelChange = (newSortModel) => {
    handleSortModelChangeHandler(
      newSortModel,
      setSortModel,
      fetchData,
      filters,
      paginationModel,
      setRows,
      setTotalRecords,
      user.token
    );
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
    fetchData(filters, sortModel, newPaginationModel, setRows, setTotalRecords, user.token);
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setOpenEditModal(true);
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDeleteModal(true);
  };

  const handleExport = async (ignore = false) => {
    const { availablePercentage } = exportData;
  
    if (availablePercentage < 80 && !ignore) {
      setOpenConfirmationDialog(true);
      return;
    }
  
    try {
      const [sort] = sortModel;
      const params = {
        ...filters,
        order: sort.field,
        sort: sort.sort,
      };
  
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/personal/export`, {
        ...params
      }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        },
        responseType: 'blob'
      });
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Datos_Exportados.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error al exportar los datos:', error);
    }
  };
  
  const handleConfirmExport = () => {
    handleExport(true);  // Llama a handleExport con ignore = true
    setOpenConfirmationDialog(false);
  };

  const calculatePercentage = (count, total) => {
    if (total === 0) return 0;
    return ((count / total) * 100).toFixed(2);
  };

  const getColor = (percentage, type) => {
    if (type === 'disponible') {
      if (percentage > 80) return '#28a745'; // Verde éxito
      if (percentage >= 75) return '#FF5F15'; // Amarillo oscuro advertencia
      return '#dc3545'; // Rojo claro peligro
    } else if (type === 'unidad') {
      if (percentage > 20) return '#dc3545'; // Rojo claro peligro
      if (percentage >= 15) return '#FF5F15'; // Amarillo oscuro advertencia
      return '#28a745'; // Verde éxito
    }
    return '#000'; // Color por defecto
  };

  const renderStatisticsBlock = () => {
    if (!statisticsData || !statisticsData.orderedObservationsUnit) return null;

    const { orderedObservationsUnit, totalConsiderarUnit, observations, totalConsiderar } = statisticsData;

    let usedObservation, usedTotal;

    if(user.role === 'admin')
    {
      usedObservation = observations
      usedTotal = totalConsiderar
    }

    else
    {
      usedObservation = orderedObservationsUnit
      usedTotal = totalConsiderarUnit
    }

    const availableCount = usedObservation['DISPONIBLE'] || 0;
    const alertAndOriginCount = (usedObservation['ALERTA SIIPNE 3W'] || 0) + (usedObservation['UNIDAD DE ORIGEN'] || 0);

    const availablePercentage = calculatePercentage(availableCount, usedTotal);
    const alertAndOriginPercentage = calculatePercentage(alertAndOriginCount, usedTotal);

    return (
      <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1, mb: 2, mt: 2, textAlign: 'center' }}>
        <Typography variant="h6">
          Disponibles: <span style={{ color: getColor(availablePercentage, 'disponible') }}>{availableCount} ({availablePercentage}%)</span> - 
          Unidad de Origen: <span style={{ color: getColor(alertAndOriginPercentage, 'unidad') }}>{alertAndOriginCount} ({alertAndOriginPercentage}%)</span>
        </Typography>
      </Box>
    );
  };

  const columns = getColumns(handleEditClick, handleDeleteClick, user.status);

  return (
    <>
      <Container maxWidth="xl" sx={{ marginTop: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}> 
          {user.status === true && (
            <AddPersonalButton setOpenAddModal={setOpenAddModal} />
          )}
          <FiltersButton filtersOpen={filtersOpen} toggleFilters={toggleFilters} />
          {user.status === true && (
            <BaseButton showBase={showBase} toggleBase={toggleBase} />
          )}
          <StatisticsButton showStatistics={showStatistics} handleStatisticsClick={toggleStatistics} />
          {buttonDisplay === true && (
            <ExportButton handleExport={handleExport} />
          )}
          
        </Box>

        {user.status === true && (
          <Base
            showBase={showBase}
            token={user.token}
          />
        )}

        <Box sx={{ marginTop: 2 }}>
          <Grid container spacing={2}>
            {user.role !== 'usuario' ? (
              <Grid item xs={12} md={3}>
                <StyledTextField
                  label="Unidad"
                  name="unit"
                  value={unitDisplay} // Usar estado de visualización
                  onChange={handleInputChange}
                  select
                  fullWidth
                  margin="normal"
                  InputProps={{
                    endAdornment: unitDisplay && (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => handleClearSelection('unit')}
                          aria-label="clear selection"
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                >
                  {units.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </StyledTextField>
              </Grid>
            ) : (
              <Grid item xs={12} md={3}>
                <Typography variant="h6" component="div" mt={3}>
                  Unidad: {user.unitName}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="Grupo"
                name="group"
                value={filters.group}
                onChange={handleInputChange}
                select
                fullWidth
                margin="normal"
                InputProps={{
                  endAdornment: filters.group && (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => handleClearSelection('group')}
                        aria-label="clear selection"
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                {['Grupo 1', 'Grupo 2', 'Grupo 3', 'Grupo 4'].map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </StyledTextField>
            </Grid>
          </Grid>
        </Box>
        
        <Filters
          filters={filters}
          setFilters={setFilters}
          handleInputChange={handleInputChange}
          handleClearSelection={handleClearSelection}
          filtersOpen={filtersOpen}
          setFiltersOpen={setFiltersOpen}
          news={news}
        />

        {showStatistics && (
          <Statistics filtersOpen={showStatistics} statisticsData={statisticsData} />
        )}
        {renderStatisticsBlock()}
        <DashboardTable
          rows={rows}
          columns={columns}
          totalRecords={totalRecords}
          paginationModel={paginationModel}
          setPaginationModel={handlePaginationModelChange}
          sortModel={sortModel}
          handleSortModelChange={handleSortModelChange}
          locale={locale}
        />
        <EditPersonalDialog
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          rowData={selectedRow}
          fetchData={fetchData}
          filters={filters}
          sortModel={sortModel}
          paginationModel={paginationModel}
          setRows={setRows}
          setTotalRecords={setTotalRecords}
          news={news}
          token={user.token}
          fetchStatisticsData={fetchStatisticsData}
          setStatisticsData={setStatisticsData}
          units={units}
        />
        <DeletePersonalDialog
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          rowData={selectedRow}
          fetchData={fetchData}
          filters={filters}
          sortModel={sortModel}
          paginationModel={paginationModel}
          setRows={setRows}
          setTotalRecords={setTotalRecords}
          token={user.token}
          fetchStatisticsData={fetchStatisticsData}
          setStatisticsData={setStatisticsData}
        />
        <AddPersonalDialog
          open={openAddModal}
          onClose={() => setOpenAddModal(false)}
          fetchData={fetchData}
          filters={filters}
          sortModel={sortModel}
          paginationModel={paginationModel}
          setRows={setRows}
          setTotalRecords={setTotalRecords}
          token={user.token}
          fetchStatisticsData={fetchStatisticsData}
          setStatisticsData={setStatisticsData}
        />
        <ConfirmationDialog
          open={openConfirmationDialog}
          onClose={() => setOpenConfirmationDialog(false)}
          onConfirm={handleConfirmExport}
          availablePercentage={exportData.availablePercentage}
        />
      </Container>
    </>
  );
};

export default Dashboard;

import React, {useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box
} from '@mui/material';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const AddPersonalDialog = ({ open, onClose, fetchData, filters, sortModel, paginationModel, setRows, setTotalRecords, token, fetchStatisticsData, setStatisticsData }) => {
  const { control, handleSubmit } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
      setFile(event.target.files[0]);
  };

  const handleAddPersonal = async () => {
    const formData = new FormData();
    if (file) {
        formData.append('file', file);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/personal/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                },
                responseType: 'blob' // Importante para manejar la respuesta binaria
            });

            const isBlob = response.headers['content-type'] === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

            if (isBlob) {
                // Crear un enlace temporal para descargar el archivo
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Errores_de_insercion.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
                onClose();
                fetchData(filters, sortModel, paginationModel, setRows, setTotalRecords, token);
                fetchStatisticsData(filters, token, setStatisticsData);
                enqueueSnackbar("Algunos datos no han sido procesados, revise el documento descargado por favor.", { variant: 'warning', autoHideDuration: 5000, style: { fontSize: '1.5rem', minWidth: '300px' } });
            } else {
                const text = await response.data.text();
                if (text === 'Datos procesados correctamente') {
                    onClose();
                    fetchData(filters, sortModel, paginationModel, setRows, setTotalRecords, token);
                    enqueueSnackbar(text, { variant: 'success', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
                } else {
                    console.error('Error inesperado:', text);
                    enqueueSnackbar(text, { variant: 'error', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
                }
            }
        } catch (error) {
            console.error('Error al añadir personal:', error);
        }
    } else {
        console.error('No se ha seleccionado ningún archivo');
    }
};

  const handleDownloadTemplate = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/personal/downloadTemplate`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'plantilla.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error al descargar la plantilla:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Añadir Personal</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddPersonal)}>
            <TextField
                type="file"
                fullWidth
                margin="normal"
                inputProps={{ accept: ".xls,.xlsx" }}
                onChange={handleFileChange} // Controla el cambio de archivo
            />
            <DialogActions sx={{ justifyContent: 'space-between' }}>
                <Button variant="contained" color="primary" onClick={handleDownloadTemplate}>
                    Descargar plantilla
                </Button>
                <Box>
                    <Button variant="contained" color="primary" sx={{ marginRight: '15px' }} onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="success" type="submit">
                        Guardar
                    </Button>
                </Box>
            </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddPersonalDialog;

import React, { useEffect, useState } from 'react';
import { Collapse, Box, Grid, Pagination } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

const Statistics = ({ filtersOpen, statisticsData }) => {
  const [chartData, setChartData] = useState({
    data1: [],
    data2: [],
    data3: [],
    data4: [],
  });
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    if (statisticsData) {
      setChartData({
        data1: Object.keys(statisticsData.groups).map(key => ({
          name: key,
          value: statisticsData.groups[key],
        })),
        data2: Object.keys(statisticsData.observations).map(key => ({
          name: key,
          value: statisticsData.observations[key],
        })),
        data3: statisticsData.units.map(unit => ({
          name: unit.unitName,
          disponible: unit.disponible_value,
          origen: unit.origen_value,
        })),
        data4: [
          { name: 'Total de unidades', value: statisticsData.unitCount },
          { name: 'Total a considerar', value: statisticsData.totalConsiderar },
          { name: 'Total', value: statisticsData.total },
        ],
      });
    }
  }, [statisticsData]);

  const renderChart = () => {
    switch (activeSlide) {
      case 0:
        return (
          <Box sx={{ maxHeight: '50vh', overflowY: 'auto', paddingLeft: '10px' }}>
            <BarChart width={1400} height={400} data={chartData.data1} layout="vertical">
              <CartesianGrid strokeDasharray="1 1" />
              <XAxis type="number" />
              <YAxis 
                type="category" 
                dataKey="name" 
                tick={{ padding: 10 }} // Ajusta el padding aquí
                width={100} // Ajusta el ancho del eje Y aquí
              />
              <Tooltip formatter={(value) => [`${value}`, 'Cantidad']} />
              <Bar dataKey="value" fill="#36a2eb" />
            </BarChart>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ maxHeight: '50vh', overflowY: 'auto', paddingLeft: '10px' }}>
            <BarChart width={1400} height={400} data={chartData.data2} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis 
                type="category" 
                dataKey="name" 
                tick={{ padding: 10 }} // Ajusta el padding aquí
                width={200} // Ajusta el ancho del eje Y aquí
              />
              <Tooltip formatter={(value) => [`${value}`, 'Cantidad']} />
              <Bar dataKey="value" fill="#4bc0c0" />
            </BarChart>
          </Box>
        );
      case 2:
        return (
          <Box sx={{ maxHeight: '80vh', overflowY: 'auto', paddingLeft: '10px' }}>
            <BarChart 
              width={1400} 
              height={Math.max(chartData.data3.length * 100, 400)} 
              data={chartData.data3} 
              layout="vertical"
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis 
                type="category" 
                dataKey="name" 
                tick={{ padding: 10 }} // Ajusta el padding aquí
                width={300} // Ajusta el ancho del eje Y aquí
              />
              <Tooltip formatter={(value, name) => [`${value}`, name]} />
              <Legend />
              <Bar dataKey="disponible" fill="#008000" />
              <Bar dataKey="origen" fill="#ff0000" />
            </BarChart>
          </Box>
        );
      case 3:
        return (
          <Box sx={{ maxHeight: '50vh', overflowY: 'auto', paddingLeft: '10px' }}>
            <BarChart width={1400} height={400} data={chartData.data4} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" />
              <YAxis 
                type="category" 
                dataKey="name" 
                tick={{ padding: 10 }} // Ajusta el padding aquí
                width={200} // Ajusta el ancho del eje Y aquí
              />
              <Tooltip formatter={(value) => [`${value}`, 'Cantidad']} />
              <Bar dataKey="value" fill="#9966ff" />
            </BarChart>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Collapse in={filtersOpen}>
      <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: 'center', mb: 2 }}>
            {renderChart()}
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Pagination
              count={4} // Actualizado a 4 para incluir la nueva estadística
              page={activeSlide + 1}
              onChange={(event, page) => setActiveSlide(page - 1)}
              color="primary"
              siblingCount={0}
            />
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
};

export default Statistics;
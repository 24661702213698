import { useState } from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const useInitialState = () => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [showBase, setShowBase] = useState(false);
  const [showStatistics, setShowStatistics] = useState(false);
  const [news, setNews] = useState([]);
  const [units, setUnits] = useState([]);
  const [rows, setRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });
  const [sortModel, setSortModel] = useState([{ field: 'CI', sort: 'asc' }]);
  const [filters, setFilters] = useState({
    CI: '',
    grade: '',
    name: '',
    charge: '',
    sex: '',
    type: '',
    designation: '',
    observation: '',
    discapacity: '',
    enf_catast_SP: '',
    pass_cause: '',
    date_cause_start: null,
    date_cause_end: null,
    NewId: '',
    address: '',
    oficio: '',
    document_detail: '',
    date_start_start: null,
    date_start_end: null,
    date_end_start: null,
    date_end_end: null,
    department: '',
    phone: '',
    group: '',
    unit: ''
  });

  const locale = 
  {
    noRowsLabel: 'No hay datos',
    footerRowSelected: count => `${count.toLocaleString()} fila(s) seleccionada(s)`,
    columnMenuLabel: 'Menú',
    columnMenuManageColumns: 'Mostrar columnas',
    columnMenuFilter: 'Filtrar',
    columnMenuHideColumn: 'Ocultar columna',
    columnMenuUnsort: 'Desordenar',
    columnMenuSortAsc: 'Ordenar ASC',
    columnMenuSortDesc: 'Ordenar DESC',
    columnHeaderFiltersTooltipActive: count => `${count} filtro(s) activo(s)`,
    columnsPanelTextFieldLabel: 'Buscar columna',
    columnsPanelTextFieldPlaceholder: 'Título de la columna',
    columnsPanelDragIconLabel: 'Reordenar columna',
    columnsPanelShowAllButton: 'Mostrar todo',
    columnsPanelHideAllButton: 'Ocultar todo',
    footerTotalRows: 'Filas Totales:',
    toolbarColumns: 'Columnas',
    toolbarColumnsLabel: 'Seleccionar columnas',
    toolbarFilters: 'Filtros',
    toolbarFiltersLabel: 'Mostrar filtros',
    toolbarDensity: 'Densidad',
    toolbarDensityLabel: 'Densidad',
    toolbarExport: 'Exportar',
    toolbarExportLabel: 'Exportar',
    filterPanelAddFilter: 'Añadir filtro',
    filterPanelDeleteIconLabel: 'Eliminar',
    filterPanelOperator: 'Operador',
    filterPanelOperatorAnd: 'Y',
    filterPanelOperatorOr: 'O',
    filterPanelColumns: 'Columnas',
    filterPanelInputLabel: 'Valor',
    filterPanelInputPlaceholder: 'Valor del filtro',
    footerRowSelected: count => `${count.toLocaleString()} fila(s) seleccionada(s)`,
    columnHeaderSortIconLabel: 'Ordenar',
    footerPaginationRowsPerPage: 'Filas por página',
    MuiTablePagination: {
      labelRowsPerPage: 'Filas por página',
    },
    columnsManagementSearchTitle: 'Buscar',
    columnsManagementNoColumns: 'No hay columnas',
    columnsManagementShowHideAllText: 'Mostrar/Ocultar todos',
    columnsManagementReset: 'Reiniciar',
    filterOperatorContains: 'contiene',
    filterOperatorEquals: 'es igual a',
    filterOperatorStartsWith: 'comienza con',
    filterOperatorEndsWith: 'termina con',
    filterOperatorIs: 'es',
    filterOperatorNot: 'no es',
    filterOperatorAfter: 'es después de',
    filterOperatorOnOrAfter: 'es en o después de',
    filterOperatorBefore: 'es antes de',
    filterOperatorOnOrBefore: 'es en o antes de',
    filterOperatorIsEmpty: 'está vacío',
    filterOperatorIsNotEmpty: 'no está vacío',
    filterOperatorIsAnyOf: 'es cualquiera de',
  };

  return {
    filtersOpen, setFiltersOpen,
    showBase, setShowBase,
    showStatistics, setShowStatistics,
    news, setNews,
    units, setUnits,
    rows, setRows,
    totalRecords, setTotalRecords,
    paginationModel, setPaginationModel,
    sortModel, setSortModel,
    filters, setFilters,
    locale
  };
};

export const getColumns = (handleEditClick, handleDeleteClick, status) => [
  { field: 'CI', headerName: 'Cedula', width: 150 },
  { field: 'grade', headerName: 'Grado', width: 150 },
  { field: 'name', headerName: 'Nombres y apellidos', width: 150 },
  { field: 'charge', headerName: 'Cargo', width: 150 },
  { field: 'sex', headerName: 'Sexo', width: 150 },
  { field: 'type', headerName: 'Tipo', width: 150 },
  { field: 'newsName', headerName: 'Novedad', width: 150 },
  { field: 'newsType', headerName: 'Observación operativa', width: 150 },
  { field: 'address', headerName: 'Sector vive', width: 150 },
  { field: 'oficio', headerName: 'Detalle', width: 150 },
  { field: 'document_detail', headerName: 'Documento', width: 150 },
  { field: 'start_date', headerName: 'Fecha inicio', width: 150 },
  { field: 'end_date', headerName: 'Fecha fin', width: 150 },
  { field: 'department', headerName: 'Departamento', width: 150 },
  { field: 'phone', headerName: 'Celular', width: 150 },
  {
    field: 'actions',
    headerName: 'Acciones',
    width: 150,
    renderCell: (params) => (
      status === true ?
      <>
        <IconButton onClick={() => handleEditClick(params.row)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDeleteClick(params.row)}>
          <DeleteIcon />
        </IconButton>
      </>
      : <></>
    ),
  },
];
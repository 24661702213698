export const handleInputChange = (e, setFilters, filters) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };
  
  export const handleClearSelection = (filterName, setFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: ''
    }));
  };
  
// handlers.js
export const handleSortModelChange = (newSortModel, setSortModel, fetchData, filters, paginationModel, setRows, setTotalRecords, token) => {
  var sortModel = [{ field: 'CI', sort: 'asc' }];
  if (newSortModel.length === 0) {
    setSortModel([{ field: 'CI', sort: 'asc' }]);
  } else {
    setSortModel(newSortModel);
    sortModel = newSortModel;
  }
  fetchData(filters, sortModel, paginationModel, setRows, setTotalRecords, token);
};


  
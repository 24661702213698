import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useForm, Controller, useWatch } from 'react-hook-form';
import axios from 'axios';

const AddUnitDialog = ({ openAddModal, setOpenAddModal, fetchUnits, token }) => {
  const { control, handleSubmit } = useForm();

  const handleAddUnit = async (data) => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/units/create`, data, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los headers
        }
      });
      
      if (response.status === 201) {
        setOpenAddModal(false);
        fetchUnits();
      } else {
        console.error('Error al crear el unidad:', response.data);
      }
    } catch (error) {
      console.error('Error al crear el unidad:', error);
    }
  };

  return (
    <Dialog open={openAddModal} onClose={() => setOpenAddModal(false)}>
      <DialogTitle>Agregar Unidad</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddUnit)}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Nombre" fullWidth margin="normal" />}
          />
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setOpenAddModal(false)}>Cancelar</Button>
            <Button variant="contained" color="success" type="submit">Agregar</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddUnitDialog;

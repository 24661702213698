import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Grid, Typography, Container, Box, Avatar } from '@mui/material';
import Logo from '../sample.png'; // Asegúrate de que la ruta sea correcta
import axios from 'axios';
import { useSnackbar } from 'notistack';

const ChangePassword = () => {
  const { token } = useParams(); // Obtiene el token de la URL
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      enqueueSnackbar('Las contraseñas no coinciden', { variant: 'error', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
      return;
    }

    if (!validatePassword(password)) {
      enqueueSnackbar('La contraseña debe tener mínimo 8 caracteres, 1 mayúscula, 1 minúscula y 1 carácter especial', { variant: 'error', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/validate-token`, { token, password });

      response.status === 200 ?
      enqueueSnackbar('Contraseña cambiada con éxito', { variant: 'success', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } }) :
      enqueueSnackbar('Token inválido. Redirigiendo a la página principal.', { variant: 'error', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });

      navigate('/');

    } catch (error) {
      console.error('Error al validar el token:', error);
      enqueueSnackbar('Error al validar el token. Redirigiendo a la página principal.', { variant: 'error', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
      navigate('/');
    }
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return regex.test(password);
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ marginTop: 15 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: 'white',
        }}
      >
        <Avatar
          alt="Logo"
          src={Logo}
          sx={{ width: 130, height: 80, mb: 2 }}
        />
        <Typography variant="h4" gutterBottom>
          Cambiar Contraseña
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nueva Contraseña"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirmar Contraseña"
                type="password"
                variant="outlined"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Cambiar Contraseña
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default ChangePassword;

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, availablePercentage }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirmar Exportación</DialogTitle>
    <DialogContent>
      <Typography variant="body1">
        El porcentaje de Disponible es actualmente {availablePercentage}%. ¿Estás seguro de que deseas exportar los datos?
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">Cancelar</Button>
      <Button onClick={onConfirm} color="primary">Confirmar</Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;

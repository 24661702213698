import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';

const EditUserDialog = ({ openEditModal, setOpenEditModal, selectedUser, fetchUsers, token }) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      const isAdminRole = selectedUser.role === 'admin';
      setIsAdmin(isAdminRole);
      reset({
        name: selectedUser.name,
        email: selectedUser.email,
        role: selectedUser.role,
        UnitId: isAdminRole ? 1 : selectedUser.UnitId,
      });
    }
  }, [selectedUser, reset]);

  const role = watch('role');
  
  const [units, setUnits] = useState([]); 

  useEffect(() => {
    fetchUnits();
  }, []);

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/personal/getAllUnits`, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los headers
        }
      });
      setUnits(response.data.data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  useEffect(() => {
    if (role === 'admin') {
      setIsAdmin(true);
      setValue('UnitId', 1); // Forzar el valor de 'unit' a 'DMQ' si el rol es admin
    } else {
      setIsAdmin(false);
    }
  }, [role, setValue]);

  const handleEditUser = async (data) => {
    try {
      // Asegurarse de que `unit` es 'DMQ' si el rol es 'admin'
      if (data.role === 'admin') {
        data.UnitId = 'DMQ';
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/edit`, 
        { ...data, id: selectedUser.id },
        {
          headers: {
            Authorization: `Bearer ${token}` // Incluye el token en los headers
          }
        }
      );

      if (response.status === 200) {
        setOpenEditModal(false);
        fetchUsers(); // Actualizar la lista de usuarios después de la edición
      } else {
        console.error('Error al editar el usuario:', response.data);
      }
    } catch (error) {
      console.error('Error al editar el usuario:', error);
    }
  };

  return (
    <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
      <DialogTitle>Editar Usuario</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleEditUser)}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Nombre" fullWidth margin="normal" />}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Correo Electrónico" fullWidth margin="normal" />}
          />
          <Controller
            name="role"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Rol</InputLabel>
                <Select {...field}>
                  <MenuItem value="usuario">Usuario final</MenuItem>
                  <MenuItem value="admin">Administrador</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {!isAdmin && (
            <Controller
              name="UnitId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Unidad</InputLabel>
                  <Select {...field} value={field.value || ''}>
                    {units.map(unit => (
                      <MenuItem key={unit.id} value={unit.id}>{unit.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setOpenEditModal(false)}>Cancelar</Button>
            <Button variant="contained" color="success" type="submit">Guardar</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditUserDialog;

import React from 'react';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const DashboardTable = ({ rows, columns, totalRecords, paginationModel, setPaginationModel, sortModel, handleSortModelChange, locale }) => {
  return (
    <Box sx={{ height: 600, width: '100%', marginTop: 3 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pagination
        paginationMode="server"
        rowCount={totalRecords}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange} // Asegúrate de pasar la función correcta aquí
        localeText={locale}
      />
    </Box>
  );
};

export default DashboardTable;

import React from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export const FiltersButton = ({ filtersOpen, toggleFilters }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      borderRadius: '4px',
      minWidth: '150px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '15px',
      textTransform: 'none',
      background: 'linear-gradient(145deg, #3f51b5, #283593)', // Le da un efecto de luz sombreado
      boxShadow: '5px 5px 10px #1c274f, -5px -5px 10px #5a6ee6', // Efecto 3D
      transition: 'box-shadow 0.3s ease', // Transición suave
      '&:hover': {
        boxShadow: '5px 5px 15px #1c274f, -5px -5px 15px #5a6ee6', // Sombra más intensa en hover
      }
    }}
    onClick={() => toggleFilters(true)}
  >
    {filtersOpen ? (
      <>
        <RemoveIcon sx={{ marginRight: '8px' }} />
        <span>Filtros</span>
      </>
    ) : (
      <>
        <AddIcon sx={{ marginRight: '8px' }} />
        <span>Filtros</span>
      </>
    )}
  </Button>
);

export const BaseButton = ({ showBase, toggleBase }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      borderRadius: '4px',
      minWidth: '150px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '15px',
      textTransform: 'none',
      background: 'linear-gradient(145deg, #3f51b5, #283593)', // Le da un efecto de luz sombreado
      boxShadow: '5px 5px 10px #1c274f, -5px -5px 10px #5a6ee6', // Efecto 3D
      transition: 'box-shadow 0.3s ease', // Transición suave
      '&:hover': {
        boxShadow: '5px 5px 15px #1c274f, -5px -5px 15px #5a6ee6', // Sombra más intensa en hover
      }
    }}
    onClick={() => toggleBase(true)}
  >
    {showBase ? (
      <>
        <RemoveIcon sx={{ marginRight: '8px' }} />
        <span>Busqueda</span>
      </>
    ) : (
      <>
        <AddIcon sx={{ marginRight: '8px' }} />
        <span>Busqueda</span>
      </>
    )}
  </Button>
);

export const StatisticsButton = ({ showStatistics, handleStatisticsClick }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      borderRadius: '4px',
      minWidth: '150px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '15px',
      textTransform: 'none',
      background: 'linear-gradient(145deg, #3f51b5, #283593)', // Le da un efecto de luz sombreado
      boxShadow: '5px 5px 10px #1c274f, -5px -5px 10px #5a6ee6', // Efecto 3D
      transition: 'box-shadow 0.3s ease', // Transición suave
      '&:hover': {
        boxShadow: '5px 5px 15px #1c274f, -5px -5px 15px #5a6ee6', // Sombra más intensa en hover
      }
    }}
    onClick={() => handleStatisticsClick(true)}
  >
        {showStatistics ? (
      <>
        <RemoveIcon sx={{ marginRight: '8px' }} />
        <span>Estadísticas</span>
      </>
    ) : (
      <>
        <AddIcon sx={{ marginRight: '8px' }} />
        <span>Estadísticas</span>
      </>
    )}
  </Button>
);

export const AddPersonalButton = ({ setOpenAddModal }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      borderRadius: '4px',
      minWidth: '150px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '15px',
      textTransform: 'none',
      background: 'linear-gradient(145deg, #3f51b5, #283593)', // Le da un efecto de luz sombreado
      boxShadow: '5px 5px 10px #1c274f, -5px -5px 10px #5a6ee6', // Efecto 3D
      transition: 'box-shadow 0.3s ease', // Transición suave
      '&:hover': {
        boxShadow: '5px 5px 15px #1c274f, -5px -5px 15px #5a6ee6', // Sombra más intensa en hover
      }
    }}
    onClick={() => setOpenAddModal(true)}
  >
    <span>Carga de nomina total de personal</span>
  </Button>
);

export const ExportButton = ({ handleExport }) => (
  <Button
    variant="contained"
    color="primary"
    sx={{
      borderRadius: '4px',
      minWidth: '150px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '15px',
      textTransform: 'none',
      background: 'linear-gradient(145deg, #3f51b5, #283593)', // Le da un efecto de luz sombreado
      boxShadow: '5px 5px 10px #1c274f, -5px -5px 10px #5a6ee6', // Efecto 3D
      transition: 'box-shadow 0.3s ease', // Transición suave
      '&:hover': {
        boxShadow: '5px 5px 15px #1c274f, -5px -5px 15px #5a6ee6', // Sombra más intensa en hover
      }
    }}
    onClick={() => handleExport(true)}
  >
    <span>Exportar</span>
  </Button>
);

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns'; // Importar el formateador de fechas
import { useInitialState } from './dashboard/constants';
import { useAuth } from '../context/AuthContext';

const Messages = () => {
  const [messages, setMessages] = useState([]);

  const { locale } = useInitialState();

  const { user } = useAuth();

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/getAllMessages`, {
        headers: {
          Authorization: `Bearer ${user.token}` // Incluye el token en los headers
        }
      });
      const messages = response.data.data.map(message => ({
        ...message,
        created_at_formatted: format(new Date(message.created_at), 'dd/MM/yyyy HH:mm:ss'), // Formatear la fecha
        id: message.id // Asegúrate de que hay un campo 'id' para las filas
      }));
      setMessages(messages);
    } catch (error) {
      console.error('Error al obtener los mensajes:', error);
    }
  };

  const columns = [
    { field: 'message', headerName: 'Mensaje', flex: 1 },
    { field: 'created_at_formatted', headerName: 'Fecha de Creación', flex: 1 }
  ];

  return (
    <Container maxWidth="xl" sx={{ marginTop: 4 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Box sx={{ height: 600, width: '100%' }}>
            <DataGrid 
              rows={messages} 
              columns={columns} 
              pageSize={5} 
              rowsPerPageOptions={[5]} 
              localeText={locale}
              sortModel={[
                {
                  field: 'created_at_formatted',
                  sort: 'desc', // Orden descendente
                },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Messages;
import React, { useState, useEffect } from 'react';
import { TextField, Box, Grid, InputAdornment, IconButton, MenuItem, Collapse, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useAuth } from '../../context/AuthContext';
import { styled } from '@mui/material/styles';

// Componente Filters
const Filters = ({ filters, setFilters, filtersOpen, news }) => {

  // Styled components
  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1976d2', // Primary color
      },
      '&:hover fieldset': {
        borderColor: '#1976d2', // Primary color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1976d2', // Primary color when focused
      },
    },
  });

  const StyledDatePicker = styled(DatePicker)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1976d2', // Primary color
      },
      '&:hover fieldset': {
        borderColor: '#1976d2', // Primary color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1976d2', // Primary color when focused
      },
    },
  });

  const [unitDisplay, setUnitDisplay] = useState(''); // Estado para la visualización

  const [disableDates, setDisableDates] = useState(false);

  const { user } = useAuth();

  useEffect(() => {
    if (user.role === 'usuario') {
      setUnitDisplay(user.unit); // Establecer valor para el campo unit
      setFilters(prevFilters => ({
        ...prevFilters,
        unit: user.unit, // Asignar unit en filtros
      }));
    }
  }, [user, setFilters]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value === 'DMQ' ? '' : value, // Asignar valor vacío para "DMQ"
    }));
  
    if (name === 'pass_cause') {
      // Si el valor es "No", limpiar los DatePickers
      if (value === 'No') {
        setFilters(prevFilters => ({
          ...prevFilters,
          date_cause_start: null,
          date_cause_end: null,
        }));
      }
    }
  
    if (name === 'unit') {
      setUnitDisplay(value); // Actualizar visualización
    }
  };

  const handleClearSelection = (field) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [field]: '',
    }));
    if (field === 'unit') {
      setUnitDisplay(''); // Limpiar visualización
    }
  };

  return (
    <Collapse in={filtersOpen}>
      <Box sx={{ marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="CI"
              name="CI"
              value={filters.CI}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Grado"
              name="grade"
              value={filters.grade}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.grade && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('grade')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {['CBOP', 'CBOS', 'CPTN', 'CRNL', 'GRAD', 'MAYR', 'POLI', 'SBOM', 'SBOP', 'SBOS', 'SBTE', 'SGOP', 'SGOS', 'TCNL', 'TNTE'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Nombre"
              name="name"
              value={filters.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Cargo"
              name="charge"
              value={filters.charge}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Sexo"
              name="sex"
              value={filters.sex}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.sex && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('sex')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {['H', 'M'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Tipo"
              name="type"
              value={filters.type}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.type && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('type')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {['A', 'I', 'J', 'L', 'R', 'S', 'T'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Sector"
              name="address"
              value={filters.address}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.address && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('address')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {['NORTE', 'SUR', 'CENTRO', 'VALLE DE LOS CHILLOS'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Novedades"
              name="NewId"
              value={filters.NewId}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.NewId && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('NewId')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {news.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Discapacidad"
              name="discapacity"
              value={filters.discapacity}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.discapacity && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('discapacity')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {['Si', 'No'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Enfermedad catastrófica"
              name="enf_catast_SP"
              value={filters.enf_catast_SP}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.enf_catast_SP && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('enf_catast_SP')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {['Si', 'No'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Departamento que labora actualmente"
              name="department"
              value={filters.department}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Designación"
              name="designation"
              value={filters.designation}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Documentos"
              name="oficio"
              value={filters.oficio}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.oficio && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('oficio')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {['MEMO', 'OFICIO', 'TELEGRAMA', 'DELEGACION'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Detalle documento"
              name="document_detail"
              value={filters.document_detail}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <StyledTextField
              label="Informe Médico"
              name="pass_cause"
              value={filters.pass_cause}
              onChange={handleInputChange}
              select
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: filters.pass_cause && (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => handleClearSelection('pass_cause')}
                      aria-label="clear selection"
                    >
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            >
              {['Si', 'No'].map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box sx={{ marginTop: 2 }}>
                <Grid container>
                  {filters.pass_cause !== 'No' && (
                    <>
                      <Grid item xs={6}>
                        <StyledDatePicker
                          label="Causa inicio"
                          value={filters.date_cause_start}
                          name="date_cause_start"
                          onChange={(date) => setFilters({ ...filters, date_cause_start: date })}
                          renderInput={(params) => <StyledTextField {...params} fullWidth />}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <StyledDatePicker
                          label="Causa fin"
                          value={filters.date_cause_end}
                          name="date_cause_end"
                          onChange={(date) => setFilters({ ...filters, date_cause_end: date })}
                          renderInput={(params) => <StyledTextField {...params} fullWidth />}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
};

export default Filters;

import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import axios from 'axios';

const DeleteUserDialog = ({ openDeleteModal, setOpenDeleteModal, selectedUser, fetchUsers, token }) => {
  
  const handleDeleteUser = async () => {
    try {
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/users/delete`, 
          { id: selectedUser.id },
          {
              headers: {
                  Authorization: `Bearer ${token}` // Incluye el token en los headers
              }
          }
      );
      if (response.status === 200) {
        setOpenDeleteModal(false);
        fetchUsers(); // Actualizar la lista de usuarios después de la eliminación
      } else {
        console.error('Error al eliminar el usuario:', response.data.message);
      }
    } catch (error) {
      console.error('Error al eliminar el usuario:', error);
    }
  };

  return (
    <Dialog open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
      <DialogTitle>Confirmar Eliminación</DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setOpenDeleteModal(false)}>Cancelar</Button>
          <Button variant="contained" color="error" onClick={handleDeleteUser}>Eliminar</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteUserDialog;

import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import Logo from '../sample.png'; // Asegúrate de que la ruta sea correcta
import { Link } from 'react-router-dom'; // Importa Link para la navegación
import MailIcon from '@mui/icons-material/Mail';

const TopBar = () => {
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/dashboard" style={{ textDecoration: 'none' }}>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <Avatar alt="Logo" src={Logo} sx={{ width: 90, height: 60 }} />
			
            </IconButton>
          </Link>
        </Box>
        {user && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* Botón de buzón */}
          {user.role === 'admin' && [
            <IconButton
              component={Link} // Añadir esto para que funcione como enlace
              to="/messages" // Especificar la ruta a donde se debe redirigir
              edge="end"
              color="inherit"
              aria-label="buzon"
              sx={{ mr: 1 }} // Añadir margen a la derecha
            >
              <MailIcon />
            </IconButton>
          ]}

            {/* Botón de usuario */}
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Typography variant="h6">{user.username}</Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {user.role === 'admin' && [
                <MenuItem key="personal" component={Link} to="/dashboard" onClick={handleClose}>
                  Personalr
                </MenuItem>,
                <MenuItem key="usuarios" component={Link} to="/users" onClick={handleClose}>
                  Usuariosr
                </MenuItem>,
                <MenuItem key="unidades" component={Link} to="/units" onClick={handleClose}>
                  Unidadesr
                </MenuItem>
              ]}
              <MenuItem onClick={handleLogout}>Cerrarr sesión</MenuItem>
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );  
  
};

export default TopBar;

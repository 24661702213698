import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';

const EditUnitDialog = ({ openEditModal, setOpenEditModal, selectedUnit, fetchUnits, token }) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm();

  useEffect(() => {
    if (selectedUnit) {
      reset({
        name: selectedUnit.name,
      });
    }
  }, [selectedUnit, reset]);

  const handleUnitUser = async (data) => {
    try {

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/units/edit`, 
        { ...data, id: selectedUnit.id },
        {
          headers: {
            Authorization: `Bearer ${token}` // Incluye el token en los headers
          }
        }
      );

      if (response.status === 200) {
        setOpenEditModal(false);
        fetchUnits();
      } else {
        console.error('Error al editar el unidad:', response.data);
      }
    } catch (error) {
      console.error('Error al editar el unidad:', error);
    }
  };

  return (
    <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)}>
      <DialogTitle>Editar Unidad</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleUnitUser)}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Nombre" fullWidth margin="normal" />}
          />
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setOpenEditModal(false)}>Cancelar</Button>
            <Button variant="contained" color="success" type="submit">Guardar</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditUnitDialog;

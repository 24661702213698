import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useForm, Controller, useWatch } from 'react-hook-form';
import axios from 'axios';

const AddUserDialog = ({ openAddModal, setOpenAddModal, fetchUsers, token }) => {
  const { control, handleSubmit } = useForm();
  const role = useWatch({
    control,
    name: 'role',
    defaultValue: '',
  });

  const [units, setUnits] = useState([]); 

  useEffect(() => {
    fetchUnits();
  }, []);

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/personal/getAllUnits`, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los headers
        }
      });
      setUnits(response.data.data);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
  };

  const handleAddUser = async (data) => {
    try {
      // Asigna la unidad 'DMQ' si el rol es 'admin'
      if (data.role === 'admin') {
        data.UnitId = 1;
      }

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/create`, data, {
        headers: {
          Authorization: `Bearer ${token}` // Incluye el token en los headers
        }
      });
      
      if (response.status === 201) {
        setOpenAddModal(false);
        fetchUsers();
      } else {
        console.error('Error al crear el usuario:', response.data);
      }
    } catch (error) {
      console.error('Error al crear el usuario:', error);
    }
  };

  return (
    <Dialog open={openAddModal} onClose={() => setOpenAddModal(false)}>
      <DialogTitle>Agregar Usuario</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleAddUser)}>
          <Controller
            name="username"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Nombre de Usuario" fullWidth margin="normal" />}
          />
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Nombre" fullWidth margin="normal" />}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Correo Electrónico" fullWidth margin="normal" />}
          />
          <Controller
            name="role"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <FormControl fullWidth margin="normal">
                <InputLabel>Rol</InputLabel>
                <Select {...field} value={field.value || ''}>
                  <MenuItem value="usuario">Usuario final</MenuItem>
                  <MenuItem value="admin">Administrador</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          {role !== 'admin' && (
            <Controller
              name="UnitId"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Unidad</InputLabel>
                  <Select {...field} value={field.value || ''}>
                    {units.map(unit => (
                      <MenuItem key={unit.id} value={unit.id}>{unit.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Contraseña" type="password" fullWidth margin="normal" />}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({ field }) => <TextField {...field} label="Confirmar Contraseña" type="password" fullWidth margin="normal" />}
          />
          <DialogActions>
            <Button variant="contained" color="primary" onClick={() => setOpenAddModal(false)}>Cancelar</Button>
            <Button variant="contained" color="success" type="submit">Agregar</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddUserDialog;

import React, { useEffect, useState } from 'react';
import { Box, Button, Switch, Container, Grid, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { useInitialState } from './dashboard/constants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddUnitDialog from './units/AddUnitDialog';
import EditUnitDialog from './units/EditUnitDialog';
import DeleteUnitDialog from './units/DeleteUnitDialog';
import { useAuth } from '../context/AuthContext';

const Units = () => {
  const [units, setUnits] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const { locale } = useInitialState();

  const { user } = useAuth();

  useEffect(() => {
    fetchUnits();
  }, []);

  const fetchUnits = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/personal/getAllUnits`, {
          headers: {
              Authorization: `Bearer ${user.token}` // Incluye el token en los headers
          }
      });
      const unitsData = response.data.data.map(unit => ({
        ...unit
      }));
      setUnits(unitsData);
    } catch (error) {
      console.error('Error al obtener los unidades:', error);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 1 },
    {
      field: 'actions',
      headerName: 'Acciones',
      renderCell: (params) => (
        <>
            <IconButton onClick={() => { setSelectedUnit(params.row); setOpenEditModal(true); }}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => { setSelectedUnit(params.row); setOpenDeleteModal(true); }}>
                <DeleteIcon />
            </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ marginTop: 4 }}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs>
            <Button variant="contained" color="primary" onClick={() => setOpenAddModal(true)} style={{ float: 'right' }}>Agregar Unidad</Button>
            </Grid>
            <Grid item xs={12}>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid rows={units} columns={columns} pageSize={5} rowsPerPageOptions={[5]} localeText={locale}/>
            </Box>
            </Grid>
        </Grid>

        <AddUnitDialog openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} fetchUnits={fetchUnits} token={user.token}/>

        <EditUnitDialog openEditModal={openEditModal} setOpenEditModal={setOpenEditModal} selectedUnit={selectedUnit} fetchUnits={fetchUnits} token={user.token}/>

        <DeleteUnitDialog openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} selectedUnit={selectedUnit} fetchUnits={fetchUnits} token={user.token}/>

    </Container>
  );
};

export default Units;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Units from './pages/Units';
import Messages from './pages/Messages';
import RecoverPassword from './pages/RecoverPassword';
import ChangePassword from './pages/ChangePassword';
import TopBar from './components/TopBar';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ element, allowedRoles }) => {
  const { user, logout } = useAuth();

  if (!user) {
    return <Navigate to="/" />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return <Navigate to="/" />;
  }

  // Check token expiration and log out if expired
  if (user.token && isTokenExpired(user.token)) {
    logout();
    return <Navigate to="/" />;
  }

  return element;
};

const PublicRoute = ({ element }) => {
  const { user } = useAuth();
  return !user ? element : <Navigate to="/dashboard" />;
};

const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000 < Date.now(); // Verifica si el token ha expirado
  } catch (e) {
    return true; // Si ocurre un error en la decodificación, considera el token expirado
  }
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<PublicRoute element={<Login />} />} />
          <Route path="/dashboard" element={<PrivateRoute element={<><TopBar /><Dashboard /></>} allowedRoles={['admin', 'usuario']} />} />
          <Route path="/users" element={<PrivateRoute element={<><TopBar /><Users /></>} allowedRoles={['admin']} />} />
          <Route path="/units" element={<PrivateRoute element={<><TopBar /><Units /></>} allowedRoles={['admin']} />} />
          <Route path="/messages" element={<PrivateRoute element={<><TopBar /><Messages /></>} allowedRoles={['admin']} />} />
          <Route path="/recover-password" element={<PublicRoute element={<RecoverPassword />} />} />
          <Route path="/change-password/:token" element={<PublicRoute element={<ChangePassword />} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;

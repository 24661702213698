import React, { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Button, TextField, Grid, Typography, Container, Box, Avatar, Snackbar, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../sample.png';

const RecoverPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setError('Por favor, introduce un correo electrónico válido.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/recover-password`, { email });
      enqueueSnackbar(response.data.message, { variant: 'success', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
      navigate('/');
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message || 'Error en el servidor', { variant: 'error', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ marginTop: 15 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: 'white',
        }}
      >
        <IconButton edge="start" color="inherit" aria-label="logo" onClick={handleLogoClick}>
          <Avatar alt="Logo" src={Logo} sx={{ width: 130, height: 80, mb: 2 }} />
        </IconButton>
        <Typography variant="h4" gutterBottom>
          Recuperar contraseña
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Correo electrónico"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError('');
                }}
                error={!!error}
                helperText={error}
              />
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message={snackbarMessage}
        />
      </Box>
    </Container>
  );
};

export default RecoverPassword;

import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, 
    Switch, TextField, Select, MenuItem, FormControl, InputLabel, Container, Grid, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useInitialState } from './dashboard/constants';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddUserDialog from './users/AddUserDialog';
import EditUserDialog from './users/EditUserDialog';
import DeleteUserDialog from './users/DeleteUserDialog';
import { useAuth } from '../context/AuthContext';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { locale } = useInitialState();

  const { user } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/getAllUsers`, {
          headers: {
              Authorization: `Bearer ${user.token}` // Incluye el token en los headers
          }
      });
      const usersData = response.data.map(user => ({
        ...user
      }));
      setUsers(usersData);
    } catch (error) {
      console.error('Error al obtener los usuarios:', error);
    }
  };

  const handleToggleStatus = async (id, status) => {
    try {
      const newStatus = status === 1 ? 0 : 1;
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/users/changeStatus`, 
          { id, status: newStatus },
          {
              headers: {
                  Authorization: `Bearer ${user.token}` // Incluye el token en los headers
              }
          }
      );
      if (response.status === 200) {
        setUsers((prevUsers) => 
          prevUsers.map((user) => 
            user.id === id ? { ...user, status: newStatus } : user
          )
        );
      } else {
        console.error('Error al cambiar el estado del usuario:', response.data.message);
      }
    } catch (error) {
      console.error('Error al cambiar el estado del usuario:', error);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Nombre', flex: 1 },
    { field: 'email', headerName: 'Correo Electrónico', flex: 1 },
    { field: 'unit', headerName: 'Unidad', flex: 1 },
    { field: 'role', headerName: 'Rol', flex: 1 },
    {
      field: 'status',
      headerName: 'Estado',
      renderCell: (params) => (
        <Switch
          checked={Boolean(params.row.status)}
          onChange={() => handleToggleStatus(params.row.id, params.row.status)}
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      renderCell: (params) => (
        <>
            <IconButton onClick={() => { setSelectedUser(params.row); setOpenEditModal(true); }}>
                <EditIcon />
            </IconButton>
            <IconButton onClick={() => { setSelectedUser(params.row); setOpenDeleteModal(true); }}>
                <DeleteIcon />
            </IconButton>
        </>
      ),
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ marginTop: 4 }}>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs>
            <Button variant="contained" color="primary" onClick={() => setOpenAddModal(true)} style={{ float: 'right' }}>Agregar Usuario</Button>
            </Grid>
            <Grid item xs={12}>
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid rows={users} columns={columns} pageSize={5} rowsPerPageOptions={[5]} localeText={locale}/>
            </Box>
            </Grid>
        </Grid>

        <AddUserDialog openAddModal={openAddModal} setOpenAddModal={setOpenAddModal} fetchUsers={fetchUsers} token={user.token}/>

        <EditUserDialog openEditModal={openEditModal} setOpenEditModal={setOpenEditModal} selectedUser={selectedUser} fetchUsers={fetchUsers} token={user.token}/>

        <DeleteUserDialog openDeleteModal={openDeleteModal} setOpenDeleteModal={setOpenDeleteModal} selectedUser={selectedUser} fetchUsers={fetchUsers} token={user.token}/>

    </Container>
  );
};

export default Users;

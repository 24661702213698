import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import axios from 'axios';

const DeletePersonalDialog = ({ open, onClose, rowData, fetchData, filters, sortModel, paginationModel, setRows, setTotalRecords, token, fetchStatisticsData, setStatisticsData }) => {
  const handleDelete = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/personal/delete`, { id: rowData.id },{
        headers: {
            Authorization: `Bearer ${token}` // Incluye el token en los headers
        }
      });
      onClose();
      fetchData(filters, sortModel, paginationModel, setRows, setTotalRecords, token);
      fetchStatisticsData(filters, token, setStatisticsData);
    } catch (error) {
      console.error('Error al eliminar el personal:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmar Eliminación</DialogTitle>
      <DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>Cancelar</Button>
          <Button variant="contained" color="error" onClick={handleDelete}>Eliminar</Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePersonalDialog;

import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { Button, TextField, Grid, Typography, Container, Link, Box, Avatar, IconButton } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import Logo from '../sample.png';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!executeRecaptcha) {
      enqueueSnackbar('reCAPTCHA no está listo', { variant: 'error' });
      return;
    }

    try {
      const token = await executeRecaptcha('login');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, { email, password, token });
      
      if (response.status === 200) {
        enqueueSnackbar(response.data.message, { variant: 'success', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
        
        login({ username : response.data.username, role: response.data.role, status: response.data.status, token: response.data.token, unit: response.data.unit, unitName: response.data.unitName });
      } else {
        enqueueSnackbar(response.data.message, { variant: 'error', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
      }
    } catch (error) {
      enqueueSnackbar(error.response?.data?.message || 'Error en el servidorRR', { variant: 'error', autoHideDuration: 2000, style: { fontSize: '1.5rem', minWidth: '300px' } });
    }
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ marginTop: 15 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 3,
          borderRadius: 2,
          boxShadow: 3,
          backgroundColor: 'white',
        }}
      >
        <IconButton edge="start" color="inherit" aria-label="logo" onClick={handleLogoClick}>
          <Avatar alt="Logo" src={Logo} sx={{ width: 130, height: 80, mb: 2 }} />
        </IconButton>
        <Typography variant="h4" gutterBottom>
          Iniciar sesión
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email de usuario"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Contraseña"
                type="password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link component={RouterLink} to="/recover-password" variant="body2">
                ¿Olvidaste tu contraseña?
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Iniciar sesión
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

const Login = () => (
  <GoogleReCaptchaProvider reCaptchaKey="6LctmBYqAAAAACyhZVmovUqjg4qnBejS4kfptfCD">
    <LoginForm />
  </GoogleReCaptchaProvider>
);

export default Login;

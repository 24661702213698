import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Collapse } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import * as XLSX from 'xlsx';
import { styled } from '@mui/material/styles';

const Base = ({ showBase, token }) => {
  const [ciValue, setCiValue] = useState('');
  const [jsonData, setJsonData] = useState([]); // Estado para almacenar el JSON cargado
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    grade: '',
    name: '',
    charge: '',
    sex: '',
    type: '',
    designation: '',
    observation: '',
    discapacity: '',
    enf_catast_SP: '',
    pass_cause: '',
    pass_date: '',
    start_date: '',
    end_date: '',
    department: '',
    phone: '',
    NewId: '',
    address: '',
    oficio: '',
    document_detail: '',
    unidad: '',
    grupo: ''
  });

  const [openModal, setOpenModal] = useState(false);

  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#1976d2', // Primary color
      },
      '&:hover fieldset': {
        borderColor: '#1976d2', // Primary color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1976d2', // Primary color when focused
      },
    },
  });

  // Función para manejar la carga del archivo Excel
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const keys = jsonData[0];
        
        const result = jsonData.slice(1).map(row => {
          let obj = {};
          row.forEach((value, index) => {
            obj[keys[index]] = value;
          });
          return obj;
        });

        setJsonData(result); // Guarda el JSON en el estado
      };
      reader.readAsArrayBuffer(file);
    }
  };

  // Función que se activa cuando se cambia el valor del campo CI
  const handleCiChange = (event) => {
    const newValue = event.target.value;
    setCiValue(newValue);

    const foundPerson = jsonData.find(person => person.Cedula == newValue);

    if (foundPerson) {
      setFormValues({
        CI: newValue,
        grade: foundPerson.Grado || '',
        name: foundPerson["Nombres y apellidos"] || '',
        charge: foundPerson.Cargo || '',
        sex: foundPerson.Sexo || '',
        type: foundPerson.Tipo || '',
        designation: foundPerson.Designación || '',
        observation: foundPerson.Observaciones || '',
        discapacity: foundPerson.Discapacidad || '',
        enf_catast_SP: foundPerson["Enfermedad catastrófica"] || '',
        pass_cause: foundPerson["Causa del pase"] || '',
        pass_date: foundPerson["Fecha del pase"] || '',
        start_date: foundPerson["Fecha de inicio"] || '',
        end_date: foundPerson["Fecha de fin"] || '',
        department: foundPerson.Departamento || '',
        phone: foundPerson.Celular || '',
        newName: foundPerson.Novedad || '',
        address: foundPerson["Sector donde vive"] || '',
        oficio: foundPerson.Oficio || '',
        document_detail: foundPerson["Detalle del documento"] || '',
        unidad: foundPerson.Unidad || '',
        grupo: foundPerson.Grupo || ''
      });
    } else {
      setFormValues({
        grade: '',
        name: '',
        charge: '',
        sex: '',
        type: '',
        designation: '',
        observation: '',
        discapacity: '',
        enf_catast_SP: '',
        pass_cause: '',
        pass_date: '',
        start_date: '',
        end_date: '',
        department: '',
        phone: '',
        newName: '',
        address: '',
        oficio: '',
        document_detail: '',
        unidad: '',
        grupo: ''
      });
    }
  };

  // Función que abre el modal de confirmación
  const handleConfirmPersonal = async () => {

    // Verificar si formValues está vacío
    if (formValues.unidad === '') {
      enqueueSnackbar("No se ha encontrado personal con el CI ingresado", { 
        variant: 'error', 
        autoHideDuration: 2000, 
        style: { fontSize: '1.5rem', minWidth: '300px' } 
      });
      return;
    }
    setOpenModal(true);
  };

  // Función que cierra el modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Función que envía los datos a la API
  const handleSubmitPersonal = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/personal/createBase`,
        formValues,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setOpenModal(false);

      let status = ''

      response.data.message == 'La unidad no coincide' ? status = 'error' : status = "success";

      enqueueSnackbar(response.data.message, { 
        variant: status, 
        autoHideDuration: 2000, 
        style: { fontSize: '1.5rem', minWidth: '300px' } 
      });
    } catch (error) {
      console.error('Error al enviar el personal', error);
      enqueueSnackbar("Error al enviar los datos del personal", { 
        variant: 'error', 
        autoHideDuration: 2000, 
        style: { fontSize: '1.5rem', minWidth: '300px' } 
      });
    }
  };

  return (
    <>
      <Collapse in={showBase}>
        {/* Contenedor de botones */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2, marginTop: 3 }}>
          <Button variant="contained" component="label" color="primary" sx={{
            borderRadius: '4px',
            minWidth: '150px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '15px',
            textTransform: 'none',
            background: 'linear-gradient(145deg, #3f51b5, #283593)', // Le da un efecto de luz sombreado
            boxShadow: '5px 5px 10px #1c274f, -5px -5px 10px #5a6ee6', // Efecto 3D
            transition: 'box-shadow 0.3s ease', // Transición suave
            '&:hover': {
              boxShadow: '5px 5px 15px #1c274f, -5px -5px 15px #5a6ee6', // Sombra más intensa en hover
            }
          }}>
            Adjuntar fichero base
            <input
              type="file"
              accept=".xls,.xlsx"
              hidden
              onChange={handleFileUpload}
            />
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirmPersonal}
            sx={{
              borderRadius: '4px',
              minWidth: '150px',
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '15px',
              textTransform: 'none',
              background: 'linear-gradient(145deg, #3f51b5, #283593)', // Le da un efecto de luz sombreado
              boxShadow: '5px 5px 10px #1c274f, -5px -5px 10px #5a6ee6', // Efecto 3D
              transition: 'box-shadow 0.3s ease', // Transición suave
              '&:hover': {
                boxShadow: '5px 5px 15px #1c274f, -5px -5px 15px #5a6ee6', // Sombra más intensa en hover
              }
            }}
          >
            Confirmar personal
          </Button>
        </Box>

        <Box sx={{ marginTop: 2, marginBottom: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="Unidad"
                name="unidad"
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                value={formValues.unidad} // Sincroniza con el valor encontrado
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="Grupo"
                name="grupo"
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                value={formValues.grupo} // Sincroniza con el valor encontrado
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ marginTop: 2, marginBottom: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="CI"
                name="CI"
                fullWidth
                margin="normal"
                value={ciValue}
                onChange={handleCiChange} // Actualiza el valor del CI
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="Grado"
                name="grade"
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                value={formValues.grade} // Sincroniza con el valor encontrado
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="Nombre"
                name="name"
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                value={formValues.name} // Sincroniza con el valor encontrado
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="Cargo"
                name="charge"
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                value={formValues.charge} // Sincroniza con el valor encontrado
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="Sexo"
                name="sex"
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                value={formValues.sex} // Sincroniza con el valor encontrado
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledTextField
                label="Tipo"
                name="type"
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
                value={formValues.type} // Sincroniza con el valor encontrado
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Confirmar Personal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas confirmar el personal? Esta acción enviará la información a la base de datos.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleSubmitPersonal} color="secondary">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );  
};

export default Base;



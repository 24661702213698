import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';

const EditPersonalDialog = ({ open, onClose, rowData, fetchData, filters, sortModel, paginationModel, setRows, setTotalRecords, news, token, fetchStatisticsData, setStatisticsData, units})  => {
  
  const formatDateTime = (date) => date ? new Date(date).toISOString().slice(0, 16) : '';

  const { user } = useAuth();

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      CI: rowData?.CI || '',
      grade: rowData?.grade || '',
      name: rowData?.name || '',
      charge: rowData?.charge || '',
      sex: rowData?.sex || '',
      type: rowData?.type || '',
      designation: rowData?.designation || '',
      observation: rowData?.observation || '',
      discapacity: rowData?.discapacity || '',
      enf_catast_SP: rowData?.enf_catast_SP || '',
      pass_cause: rowData?.pass_cause || '',
      NewId: rowData?.NewId || '',
      address: rowData?.address || '',
      oficio: rowData?.oficio || '',
      document_detail: rowData?.document_detail || '',
      department: rowData?.department || '',
      phone: rowData?.phone || '',
      group: rowData?.group || '',
      UnitId: rowData?.UnitId || '',
    }
  });

  const [dateCause, setDateCause] = useState( rowData ? formatDateTime(rowData.date_cause) : '');
  const [startDate, setStartDate] = useState(rowData?.start_date ? formatDateTime(rowData.start_date) : '');
  const [endDate, setEndDate] = useState(rowData?.end_date ? formatDateTime(rowData.end_date) : '');
  

  useEffect(() => {
    
    if (rowData) {
      setDateCause(formatDateTime(rowData.date_cause))
      setStartDate(formatDateTime(rowData.start_date))
      setEndDate(formatDateTime(rowData.end_date))
      reset(rowData)
    }
  }, [rowData, reset]);

  const handleEdit = async (data) => {
    try {
      // Send data directly as it already contains the datetime in the correct format
      await axios.post(`${process.env.REACT_APP_API_URL}/api/personal/edit`, {
        id: rowData.id,
        ...data,
        date_cause: dateCause,
        start_date: startDate,
        end_date: endDate
      },{
        headers: {
            Authorization: `Bearer ${token}` // Incluye el token en los headers
        }
      });
      onClose();
      fetchData(filters, sortModel, paginationModel, setRows, setTotalRecords, token)
      fetchStatisticsData(filters, token, setStatisticsData);
    } catch (error) {
      console.error('Error al editar el personal:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Editar Personal</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleEdit)}>
          {user.role === 'admin' ? (
            <>
              <Controller
                name="CI"
                control={control}
                render={({ field }) => <TextField {...field} label="Cedula" fullWidth margin="normal" />}
              />
              <Controller
                name="grade"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Grado</InputLabel>
                    <Select {...field}>
                      {['CBOP', 'CBOS', 'CPTN', 'CRNL', 'GRAD', 'MAYR', 'POLI', 'SBOM', 'SBOP', 'SBOS', 'SBTE', 'SGOP', 'SGOS', 'TCNL', 'TNTE'].map(grade => (
                        <MenuItem key={grade} value={grade}>{grade}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="name"
                control={control}
                render={({ field }) => <TextField {...field} label="Nombres y apellidos" fullWidth margin="normal" />}
              />
              <Controller
                name="charge"
                control={control}
                render={({ field }) => <TextField {...field} label="Cargo" fullWidth margin="normal" />}
              />
              <Controller
                name="sex"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Sexo</InputLabel>
                    <Select {...field}>
                      {['H', 'M'].map(sex => (
                        <MenuItem key={sex} value={sex}>{sex}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Tipo</InputLabel>
                    <Select {...field}>
                      {['A', 'I', 'J', 'L', 'R', 'S', 'T'].map(type => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="designation"
                control={control}
                render={({ field }) => <TextField {...field} label="Designación" fullWidth margin="normal" />}
              />
              <Controller
                name="observation"
                control={control}
                render={({ field }) => <TextField {...field} label="Observaciones" fullWidth margin="normal" />}
              />
              <Controller
                name="discapacity"
                control={control}
                render={({ field }) => <TextField {...field} label="Discapacidad" fullWidth margin="normal" />}
              />
              <Controller
                name="enf_catast_SP"
                control={control}
                render={({ field }) => <TextField {...field} label="Enfermedad catastrófica" fullWidth margin="normal" />}
              />
              <Controller
                name="pass_cause"
                control={control}
                render={({ field }) => <TextField {...field} label="Causa del pase" fullWidth margin="normal" />}
              />
              <TextField
                label="Fecha del pase"
                type="datetime-local"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={dateCause}
                onChange={(e) => setDateCause(e.target.value)}
              />
              <Controller
                name="NewId"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Novedad</InputLabel>
                    <Select {...field}>
                      {news.map((newsItem) => (
                        <MenuItem key={newsItem.id} value={newsItem.id}>{newsItem.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Sector donde vive</InputLabel>
                    <Select {...field}>
                      {['ELOY ALFARO', 'QUITUMBE', 'MANUELA SAENZ', 'TUMBACO', 'LA DELICIA', 'CARAPUNGO', 'LOS CHILLOS', 'NANEGAL'].map(address => (
                        <MenuItem key={address} value={address}>{address}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="oficio"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Oficio</InputLabel>
                    <Select {...field}>
                      {['MEMO', 'OFICIO', 'TELEGRAMA', 'DELEGACION'].map(oficio => (
                        <MenuItem key={oficio} value={oficio}>{oficio}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="document_detail"
                control={control}
                render={({ field }) => <TextField {...field} label="Detalle del documento" fullWidth margin="normal" />}
              />
              <TextField
                label="Fecha de inicio"
                type="datetime-local"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <TextField
                label="Fecha de fin"
                type="datetime-local"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
              <Controller
                name="department"
                control={control}
                render={({ field }) => <TextField {...field} label="Departamento" fullWidth margin="normal" />}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => <TextField {...field} label="Celular" fullWidth margin="normal" />}
              />
              <Controller
                name="group"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Grupo</InputLabel>
                    <Select {...field}>
                      {['Grupo 1', 'Grupo 2', 'Grupo 3', 'Grupo 4'].map(group => (
                        <MenuItem key={group} value={group}>{group}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
              <Controller
                name="UnitId"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Unidad</InputLabel>
                    <Select {...field}>
                      {units
                        .filter(unitItem => unitItem.id !== 1) // Filtra el item con id 1
                        .map((unitItem) => (
                          <MenuItem key={unitItem.id} value={unitItem.id}>
                            {unitItem.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                )}
              />
            </>
          ) : (
            // Mostrar solo el campo "Novedad" si no es admin
            <Controller
              name="NewId"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Novedad</InputLabel>
                  <Select {...field}>
                    {news.map((newsItem) => (
                      <MenuItem key={newsItem.id} value={newsItem.id}>{newsItem.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          )}
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onClose}>Cancelar</Button>
            <Button variant="contained" color="success" type="submit">Guardar</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );  
};

export default EditPersonalDialog;

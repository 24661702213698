import axios from 'axios';

export const fetchNews = async (setNews, token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/personal/getAllNews`, {
      headers: {
        Authorization: `Bearer ${token}` // Incluye el token en los headers
      }
    });
    setNews(response.data.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const fetchUnits = async (setUnits, token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/personal/getAllUnits`, {
      headers: {
        Authorization: `Bearer ${token}` // Incluye el token en los headers
      }
    });
    setUnits(response.data.data);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const fetchData = async (filters, sortModel, paginationModel, setRows, setTotalRecords, token) => {
  try {
    const [sort] = sortModel;
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/personal/getAllPersonal`, {
      ...filters,
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      order: sort.field,
      sort: sort.sort,
    }, {
      headers: {
        Authorization: `Bearer ${token}` // Incluye el token en los headers
      }
    });
    setRows(response.data.data);
    setTotalRecords(response.data.totalRecords);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

export const fetchStatisticsData = async (filters, token, setStatisticsData) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/personal/getAllPersonalChart`, filters, {
      headers: {
        Authorization: `Bearer ${token}` // Incluye el token en los headers
      }
    });
    setStatisticsData(response.data); // Guarda la respuesta en el estado de statisticsData
  } catch (error) {
    console.error('Error fetching statistics data:', error);
  }
};
